/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    AOS.init({
        duration: 650,
        easing: 'ease-out',
    });
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
          (function (window) {

              SlideCrossFade = {
                  container: 'images',
                  displayTime: 5000,
                  fadeTime: 1500,

                  start: function () {
                      var self = this;
                      $('.' + this.container + ' img').show();

                      setInterval(function () {
                          self.next();
                      }, this.displayTime);
                  },

                  next: function () {
                      var $active = $('.' + this.container + ' .active');
                      var $next = ($active.next().length > 0) ? $active.next() : $('.' + this.container + ' img:first');
                      $next.css('z-index', 2);

                      $active.fadeOut(this.fadeTime, function () {
                          $active.css('z-index', 1).show().removeClass('active');
                          $next.css('z-index', 3).addClass('active');
                      });
                  }
              };

              window.SlideCrossFade = SlideCrossFade;

          })(this);

          $(window).on('load', function () {
              SlideCrossFade.start();
          });

          var modal = document.getElementById('myModal');
          var nlModal = document.getElementById('newsletter-modal');
          var gutModal = document.getElementById('gutschein-modal');

          $(".res_btn_link").on('click', function(e) {
              e.preventDefault();
              $('#myModal').toggleClass('active');
          });

          var span = document.getElementsByClassName("close")[0];

          span.onclick = function() {
              $('#myModal').toggleClass('active');
          };

          window.onclick = function(event) {
              if (event.target === modal) {
                  $('#myModal').toggleClass('active');
              }

              if (event.target === nlModal) {
                  $('#newsletter-modal').toggleClass('active');
              }
          };

          $('.gutschein-menu-btn').on('click', function(e) {
              e.preventDefault();
              $('#gutschein-modal').toggleClass('active');
          });

          $('.nl-menu-btn').on('click', function(e) {
              e.preventDefault();
              $('#newsletter-modal').toggleClass('active');
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
          var $grid = $('.grid').masonry({
              "initLayout": true,
              "itemSelector": ".grid-item",
              "layoutMode": "fitColumns",
              "gutter": 15
          });

          $grid.imagesLoaded().progress( function() {
              $grid.masonry('layout');
          });

          //DISPLAY NEWSLETTER ON HOME
          //$('#newsletter-modal').toggleClass('active');

          if($('#info-modal').attr('data-autoopen') == "1") {
              $('#info-modal').toggleClass('active');
          }
      }
    },
      'single_karten': {
        finalize: function() {
            // Hide Header on on scroll down
            var didScroll;
            var lastScrollTop = 0;
            var delta = 0.2;
            var navbarHeight = 10;

            $(window).scroll(function(event){
                didScroll = true;
            });

            function hasScrolled() {
                var st = $(this).scrollTop();

                // Make sure they scroll more than delta
                if(Math.abs(lastScrollTop - st) <= delta){
                    return;
                }

                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st > lastScrollTop && st > navbarHeight){
                    // Scroll Down
                    $('.nav-btn').addClass('hidden');
                } else {
                    // Scroll Up
                    if(st + $(window).height() < $(document).height()) {
                        $('.nav-btn').removeClass('hidden');
                    }
                }

                lastScrollTop = st;
            }

            setInterval(function() {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 250);
        }
      },
      'page': {
          finalize: function() {
              // Hide Header on on scroll down
              var didScroll;
              var lastScrollTop = 0;
              var delta = 5;
              var navbarHeight = $(window).height();

              $(window).scroll(function(event){
                  didScroll = true;
              });

              function hasScrolled() {
                  var st = $(this).scrollTop();

                  // Make sure they scroll more than delta
                  if(Math.abs(lastScrollTop - st) <= delta){
                      return;
                  }

                  // If they scrolled down and are past the navbar, add class .nav-up.
                  // This is necessary so you never see what is "behind" the navbar.
                  if (st > lastScrollTop && st > navbarHeight){
                      // Scroll Down
                      $('.nav-btn').addClass('hidden');
                  } else {
                      // Scroll Up
                      if(st + $(window).height() < $(document).height()) {
                          $('.nav-btn').removeClass('hidden');
                      }
                  }

                  lastScrollTop = st;
              }

              setInterval(function() {
                  if (didScroll) {
                      hasScrolled();
                      didScroll = false;
                  }
              }, 250);
          }
      },
    // About us page, note the change from about-us to about_us.
    'galerie': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
        finalize: function() {
            var $grid = $('.grid').masonry({
                "initLayout": true,
                "itemSelector": ".grid-item",
                "layoutMode": "fitColumns",
                "gutter": 15
            });

            $grid.imagesLoaded().progress( function() {
                $grid.masonry('layout');
            });
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
